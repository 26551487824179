import PropTypes from 'prop-types';
import { chunk } from 'underscore';
import { Carousel } from 'bv-components';
import TrendingBetsCard from './trending_bets_card';

const Layout = ({ trendingBets, perCard }) => (
  trendingBets.length <= perCard
    ? (
      <div className="trending-bets-single">
        <TrendingBetsCard trendingBetsGroup={trendingBets} />
      </div>
    ) : (
      <Carousel scrollStep={284} largeArrows>
        { chunk(trendingBets, perCard).map((trendingBetsGroup) => (
          <TrendingBetsCard trendingBetsGroup={trendingBetsGroup} />
        )) }
      </Carousel>
    )
);

Layout.propTypes = {
  trendingBets: PropTypes.arrayOf(Object).isRequired,
  perCard: PropTypes.number.isRequired,
};

export default Layout;
