import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { t } from 'bv-i18n';
import { Skeleton, Icon } from 'bv-components';

import { trendingBetsFetchData, trendingBetsReset } from '../duck';
import { makeGetComponentById } from '../selectors';
import Layout from './layout';

const TrendingBetsApp = ({
  componentId,
  numberPerCard = 1,
}) => {
  const dispatch = useDispatch();
  const getComponentById = makeGetComponentById();
  const component = useSelector(
    (state) => getComponentById(state, componentId),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(trendingBetsFetchData(componentId));

    return () => dispatch(trendingBetsReset(componentId));
  }, [componentId]);

  if (!component) return null;

  const { bets, loaded } = component;

  if (!loaded) return <Skeleton skeletonType="trending-bets-wrapper" />;

  return bets.length > 0 && (
    <div className="trending-bets-wrapper">
      <h2 className="bvs-header bvs-ellipsis">
        <Icon className="bvs-event-icon is-trending-bets" big />
        {t('javascript.trending_bets')}
      </h2>
      <Layout trendingBets={bets} perCard={numberPerCard} />
    </div>
  );
};

TrendingBetsApp.propTypes = {
  componentId: PropTypes.number.isRequired,
  numberPerCard: PropTypes.number.isRequired,
};

export default TrendingBetsApp;
