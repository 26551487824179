import { getJSON } from 'bv-fetch';

const reduceOutcomes = (acc, current) => [
  ...acc,
  {
    id: current.outcome_id,
    desc: current.outcome_description,
    prid: current.price_id,
    prd: current.price_euro,
    pr: current.price_custom_price_format,
  },
];

const reduceTrendingBets = (acc, current) => [
  ...acc,
  {
    eventId: current.event_id,
    eventStartTime: current.event_start_time,
    meetingId: current.meeting_id,
    sportEventPathId: current.sport_event_path_id,
    outcomeId: current.outcome_id,
    outcomeDescription: current.outcome_description,
    sportDesc: current.sport_description,
    meetingDesc: current.meeting_description,
    eventDesc: current.event_description,
    marketDesc: current.market_description,
    periodDesc: current.period_description,
  },
];

const present = (JSONResponse) => ({
  outcomes: JSONResponse.reduce(reduceOutcomes, []),
  trendingBets: JSONResponse.reduce(reduceTrendingBets, []),
});

const emptyResponse = () => [];

export default (componentId) => (
  getJSON(`/sportsbook_components/home_components/components/${componentId}`)
    .catch(emptyResponse)
    .then(present)
);
