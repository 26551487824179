import { property, omit } from 'underscore';
import { addOutcomes, removeOutcomes } from 'sportsbook-outcomes-duck';
import { makeGetComponentById } from './selectors';
import fetchData from './api';

export const TRENDING_BETS_FETCH_DATA_INIT = 'trendingBets/FETCH_DATA_INIT';
export const TRENDING_BETS_FETCH_DATA_SUCCESS = 'trendingBets/FETCH_DATA_SUCCESS';
export const TRENDING_BETS_RESET = 'trendingBets/RESET';

export const trendingBetsFetchDataInit = (componentId) => ({
  type: TRENDING_BETS_FETCH_DATA_INIT,
  componentId,
});

export const trendingBetsFetchDataSuccess = (componentId) => (trendingBets) => ({
  type: TRENDING_BETS_FETCH_DATA_SUCCESS,
  componentId,
  trendingBets,
});

export const trendingBetsResetSuccess = (componentId) => ({
  type: TRENDING_BETS_RESET,
  componentId,
});

export const trendingBetsReset = (componentId) => (dispatch, getState) => {
  const getComponentById = makeGetComponentById();
  const component = getComponentById(getState(), componentId);
  const outcomeIds = component && component.bets
    ? component.bets.map(property('outcomeId'))
    : [];

  dispatch(removeOutcomes(outcomeIds));
  dispatch(trendingBetsResetSuccess(componentId));
};

export const trendingBetsFetchData = (componentId) => (dispatch) => {
  dispatch(trendingBetsFetchDataInit(componentId));
  return fetchData(componentId)
    .then((data) => {
      dispatch(addOutcomes(data.outcomes));
      dispatch(trendingBetsFetchDataSuccess(componentId)(data.trendingBets));
    });
};

const initialState = {
  componentsById: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TRENDING_BETS_FETCH_DATA_INIT:
      return {
        componentsById: {
          ...state.componentsById,
          [action.componentId]: {
            bets: [],
            loaded: false,
          },
        },
      };
    case TRENDING_BETS_FETCH_DATA_SUCCESS:
      return {
        componentsById: {
          ...state.componentsById,
          [action.componentId]: {
            bets: action.trendingBets,
            loaded: true,
          },
        },
      };
    case TRENDING_BETS_RESET:
      return {
        componentsById: {
          ...omit(state.componentsById, action.componentId),
        },
      };
    default:
      return state;
  }
};
