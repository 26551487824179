import reducer from './duck';
import App from './components/app';

const { addReducers } = window.reduxState;

addReducers({
  trendingBets: reducer,
});

export default App;
