import PropTypes from 'prop-types';

import TrendingBet from './trending_bet';

const TrendingBetsCard = ({ trendingBetsGroup }) => (
  <div className="bvs-card trending-bets-card">
    { trendingBetsGroup.map((trendingBet) => (
      <TrendingBet {...trendingBet} key={trendingBet.eventId} />
    )) }
  </div>
);

TrendingBetsCard.propTypes = {
  trendingBetsGroup: PropTypes.arrayOf(Object).isRequired,
};

export default TrendingBetsCard;
