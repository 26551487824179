import PropTypes from 'prop-types';

import withOutcome from 'sportsbook-with-outcome';

export const TrendingBetOutcome = ({ selected, toggleBetslip, outcome }) => {
  const outcomeStyle = selected ? 'active' : '';

  return (
    <div className={`bvs-button-sport is-square trending-bets-button ${outcomeStyle}`} onClick={toggleBetslip}>
      <div className="trending-bets-button__wrapper">
        <span className="trending-bets-button__price">{outcome.formattedPrice}</span>
      </div>
    </div>
  );
};

TrendingBetOutcome.propTypes = {
  outcome: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
};

export default withOutcome(TrendingBetOutcome);
