import PropTypes from 'prop-types';
import { preEventPath } from 'bv-helpers/routes';
import { Link, Icon } from 'bv-components';
import Outcome from './trending_bet_outcome';

const TrendingBet = ({
  sportEventPathId,
  eventId,
  eventDesc,
  eventStartTime,
  meetingId,
  meetingDesc,
  outcomeId,
  outcomeDescription,
  marketDesc,
}) => {
  const navigationParams = {
    eventId,
    meetingId,
    sportEventPathId,
  };

  const eventHref = preEventPath(navigationParams);
  const getFormatedStartTime = () => {
    const d = new Date(eventStartTime);
    return `${d.getHours()}:${(0 + d.getMinutes().toString()).slice(-2)}`;
  };

  const trendingBetDescription = [
    364, // Horse Racing Event Path Id
    1000, // Greyhounds Event Path Id
  ].includes(sportEventPathId)
    ? `${marketDesc} - ${meetingDesc} | ${eventDesc}`
    : `${marketDesc} - ${eventDesc} | ${getFormatedStartTime()}`;

  return (
    <div className="trending-bets-item">
      <Link className="trending-bets-link" to={eventHref} aria-label={trendingBetDescription} />
      <div className="trending-bets-item-header">
        <Icon type="trending-bets-item-header-icon" className={`bvs-link is-sport-${sportEventPathId}`} />
        <h4 className="trending-bets-item-title bvs-ellipsis">{outcomeDescription}</h4>
      </div>
      <div className="trending-bets-item-description">
        { trendingBetDescription }
      </div>
      <Outcome id={outcomeId} clickSource="trending-bets-widget" />
    </div>
  );
};

TrendingBet.propTypes = {
  outcomeId: PropTypes.number.isRequired,
  outcomeDescription: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  eventStartTime: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  meetingDesc: PropTypes.string.isRequired,
  sportEventPathId: PropTypes.number.isRequired,
  eventDesc: PropTypes.string.isRequired,
  marketDesc: PropTypes.string.isRequired,
};

export default TrendingBet;
